/* You can add global styles to this file, and also import other style files */

@import "../node_modules/primeng/resources/primeng.min.css";
@import "../node_modules/primeflex/primeflex.scss";
@import "../node_modules/primeicons/primeicons.css";
@import "../node_modules/prismjs/themes/prism-coy.css";
// @import "../node_modules/@fullcalendar/daygrid/main.min.css";
// @import "../node_modules/@fullcalendar/timegrid/main.min.css";
@import "assets/demo/flags/flags.css";

@font-face {
    font-family: 'Material Icons';
    font-style: normal;
    font-weight: 400;
    src: local('Material Icons'),
      local('MaterialIcons-Regular'),
      url(assets/icon/material/MaterialIcons-Regular.ttf) format('truetype');


  }

.material-icons {
    font-family: 'Material Icons';
    font-weight: normal;
    font-style: normal;
    font-size: 24px;  /* Preferred icon size */
    display: inline-block;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    direction: ltr;

    /* Support for all WebKit browsers. */
    -webkit-font-smoothing: antialiased;
    /* Support for Safari and Chrome. */
    text-rendering: optimizeLegibility;

    /* Support for Firefox. */
    -moz-osx-font-smoothing: grayscale;

    /* Support for IE. */
    font-feature-settings: 'liga';
  }

  .p-invalid
  {
    font-weight: 500;
    font-size: 12px;
    color: red;

  }

.fontToolBarTabellioWhite
{
    font-family: 'Raleway', 'Montserrat', sans-serif;
    font-weight: 600;
    font-size: 24px;
    color: white;
    height: 50px !important;
    margin-left: 5px;
    margin-top: -10px;
}

.fontToolBarTabellioOrange
{
    font-family:  'Raleway', 'Montserrat', sans-serif;
    font-weight: 600;
    font-size: 24px;
    color: orange;
}

.fontOrangeBig
{
    font-family:  'Raleway', 'Montserrat', sans-serif;
    font-weight: 800;
    font-size: 20px;
    color: orange;
    margin-top: 15px;
}


.fontLogoWhite
{
    font-family: 'Raleway', 'Montserrat', sans-serif;
    font-weight: 800;
    font-size: 18px;
    color: white;
    text-align: center;
    margin: auto;
}
.fontFooterTabellioWhite
{
    font-family: 'Raleway', 'Montserrat', sans-serif;
    font-weight: 500;
    font-size: 14px;
    color: white;
    margin-left: 5px;
}

.fontMenuFooterWhite
{
    font-family: 'Raleway', 'Montserrat', sans-serif;
    font-weight: 500;
    font-size: 14px;
    color: white;

}

.fontMenuFooterBlack
{
    font-family: 'Raleway', 'Montserrat', sans-serif;
    font-weight: 500;
    font-size: 14px;
    color: black;

}


.fontMenuFooterOrange
{
    font-family:  'Raleway', 'Montserrat', sans-serif;
    font-weight: 500;
    font-size: 20px;
    color: orange;
    margin-left: 10px;
}

.fontLoginTabellio
{
    font-family:  'Raleway', 'Montserrat', sans-serif;
    font-weight: 600;
    font-size: 60px;
    color: black;
    margin-left: 10px;
}

.fontLoginTabellioSlogan
{
    font-family:  'Raleway', 'Montserrat', sans-serif;
    font-weight: 600;
    font-size: 20px;
    color: black;
    margin-left: 10px;
}



.footer {
    position: absolute;
    bottom: 0;
    width: 100%; /* Ajuste conforme necessário */
    background-color: #242527; /* Ajuste conforme necessário */
    padding: 10px; /* Ajuste conforme necessário */
    text-align: center; /* Ajuste conforme necessário */
    height: 50px;
  }


.fontLogoCriaMedio {
    font-family: 'Patrick Hand', sans-serif;
    font-size: 24px;
    color: orange;
}

.fontLogoServiceMedio {
    font-family: 'Patrick Hand', sans-serif;
    font-size: 24px;
    color: orange;
}

.fontLogoCampoMedio {
    font-family: 'Patrick Hand', sans-serif;
    font-size: 18px;
    color: white;
}

.fontLogoCriaGrande {
    font-family: 'Patrick Hand', sans-serif;
    font-size: 32px;
    color: green;
}

.fontLogoCriaGrandeBranco {
    font-family: 'Patrick Hand', sans-serif;
    font-size: 32px;
    color: white;
}


.fontLogoServiceGrande {
    font-family: 'Patrick Hand', sans-serif;
    font-size: 32px;
    color: orange;
    margin-top: -10px !important;
}

.fontLogoCampoGrande {
    font-family: 'Patrick Hand', sans-serif;
    font-size: 24;
    color: green;
}

.fontLoginCria {
    font-family: 'Patrick Hand', sans-serif;
    font-size: 40px;
    color: #125386;
}

.fontLoginService {
    font-family: 'Patrick Hand', sans-serif;
    font-size: 40px;
    color: orange;

}

.dialog-label {
    color: gray;
    font-weight: bold;
}

.dialog-page {
    background-color: #FFFFFF;
}

.dialog-page label {
    color: gray;
    font-weight: bold;
}

.status-badge {
    border-radius: 5px;
    padding: .25em .5rem;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: .3px;
    display: block;
    text-align: center;
}

.status-badge-small {
    border-radius: 5px;
    padding: .25em .5rem;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 14px;
    letter-spacing: .3px;
    text-align: center;
}


.sfbreadcrumb {
    padding: 6px 12px;
    margin-top: 10px;
    font-size: 12px;
    background-color: transparent !important;
    width: 100%;
    height: 98%;
    margin-left: 2px;
    margin-right: 2px;

}

.sfbreadcrumb a {
    color: #337ab7;
    text-decoration: solid;
    font-weight: bold;
}

.p-multiselect-items-wrapper {
    overflow-y: scroll !important;
}


